<template>
  <div :style="{ minHeight: $vuetify.breakpoint.xs ? 'auto' : '539px' }" class="d-flex flex-grow-1 align-center pb-12">
    <v-card :width="$vuetify.breakpoint.xs ? '100%' : '360'" height="full-height" flat>
      <v-card-text>
          <h4 class="font-weight-black mb-2 text-center text-sm-left" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Confirm Password Reset</h4>

          <v-sheet class="mx-auto" :max-width="$vuetify.breakpoint.xs ? '250' : '100%'">
            <p class="body-1 mb-5 text-center text-sm-left">
              Please provide your new password.
            </p>
          </v-sheet>

        <v-alert v-if="status.error" type="error" border="left" class="mb-5" text>
          {{ status.error }}
        </v-alert>
      
        <v-alert v-if="status.success" type="success" border="left" text>
          Your password was successfully updated. Back to <router-link :to="{ name: 'Login' }">login page</router-link>.
        </v-alert>

        <div v-if="!status.success">
          <v-form ref="loginForm" @submit.prevent="validateSignin()">
            <v-text-field
              v-model="newPassword"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.required]"
              height="48"
              outlined
              dense
            ></v-text-field>
          </v-form>

          <v-btn
            @click="validateForm()"
            :loading="status.confirming"
            color="accent gradient"
            class="rounded-lg"
            block
          >Update Password</v-btn>

          <div class="mt-5">
            <router-link
              :to="{ name: 'Login' }"
              class="body-1 black--text caption"
            >
              Back to login page
            </router-link>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "@/rules";

export default {
  data() {
    return {
      rules,
      newPassword: null,
      showPassword: false,
    }
  },

  computed: {
    ...mapState({
      status: (state) => state.user.status,
    }),
  },

  methods: {
    ...mapActions("user", ["confirmResetPassword"]),

    validateForm() {
      if (this.$refs.loginForm.validate()) {
        let data = {
          password: this.newPassword,
          code: this.$route.query.oobCode
        }

        this.confirmResetPassword(data)
      }
    },
  },

  mounted() {
    this.$store.commit('user/setError', false)
  }
}
</script>

<style lang="scss">
.v-main {
  &.withBackground {
    .v-main__wrap {
      background: url("/images/bg1.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}
</style>
